import FuseNavigation from '@ameroservices-platform/attraction-backend/fuse/core/FuseNavigation';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import clsx from 'clsx';
import React, { memo, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	selectNavigation,
	appendNavigationItem,
	removeNavigationItem,
	updateNavigationItem as updateNavigationItemFuse
} from '@ameroservices-platform/attraction-backend/app/store/fuse/navigationSlice';
import { navbarCloseMobile } from '../../store/fuse/navbarSlice';
import { navigationsListener } from '../../main/apps/frontendSettings/navigations/navigationsSlice';
import {
	eventGroupsForSchedulersNavigationListener,
	eventGroupsForEventsNavigationListener,
	eventAttributeGroupsForNavigationListener,
	ticketTypesForNavigationListener,
	pageContentsForContentNavigationListener,
	eventGroupsForEventsNavigationListenerFeatureFlagEventGroupType,
	locationsNavigationListener,
	eventAttributeGroupsListener
} from './navigationFirestore';
import firebaseService from '@ameroservices-platform/shared/services/firebase';
import FuseUtils from '@ameroservices-platform/shared/fuse/utils';
import authRoles from '@ameroservices-platform/attraction-backend/app/auth/authRoles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import eventGroupType, { eventGroupTypeTranslate } from '@ameroservices-platform/shared/enums/eventGroupType';
import eventGroupState from '@ameroservices-platform/attraction-backend/app/main/apps/eventGroups/eventGroupState';
import { removeNavChildrenNoState } from '../../../AttUtils';
import eventSchedulingType from '@ameroservices-platform/shared/enums/eventSchedulingType';

function Navigation(props) {
	const navigation = useSelector(selectNavigation);
	const flags = useFlags();

	const theme = useTheme();
	const mdDown = useMediaQuery(theme.breakpoints.down('lg'));
	const dispatch = useDispatch();

	const organisationId = firebaseService.getOrganisationId();
	const userRole = useSelector(({ auth }) => auth.user.role);
	const hasAdminPermission = useMemo(() => FuseUtils.hasPermission(authRoles.admin, userRole), [userRole]);
	const hasUserPermission = useMemo(() => FuseUtils.hasPermission(authRoles.user, userRole), [userRole]);
	const modules = firebaseService.getOrganisationModules();
	const [eventGroups, setEventGroups] = React.useState([]);
	const [locations, setLocations] = React.useState(null);
	const [eventAttributeGroups, setEventAttributeGroups] = React.useState(null);

	const filterNavigationChildren = React.useCallback(
		navRow => {
			const extra = {};
			if (navRow.children) {
				extra.children = navRow.children
					.filter(_navRow => {
						if (!_navRow.requireModule) {
							return true;
						}
						if (typeof _navRow.requireModule === 'string') {
							return modules && modules[_navRow.requireModule];
						}
						if (typeof _navRow.requireModule === 'object') {
							return modules && _navRow.requireModule.some(requiredModule => modules[requiredModule]);
						}
						return true;
					})
					.map(children => {
						return filterNavigationChildren(children);
					});
			}
			return { ...navRow, ...extra };
		},
		[modules]
	);

	const filteredNavigation = useMemo(() => {
		return navigation
			.filter(navRow => {
				return !(navRow.requireOrganisation && !organisationId);
			})
			.filter(navRow => {
				if (!navRow.requireModule) {
					return true;
				}
				if (typeof navRow.requireModule === 'string') {
					return modules && modules[navRow.requireModule];
				}
				if (typeof navRow.requireModule === 'object') {
					return modules && navRow.requireModule.some(requiredModule => modules[requiredModule]);
				}
				return true;
			})
			.map(navRow => {
				return filterNavigationChildren(navRow);
			});
	}, [navigation, organisationId, modules, filterNavigationChildren]);

	useEffect(() => {
		if (organisationId && hasAdminPermission) {
			const unsubNavigations = dispatch(navigationsListener());
			let unsubEventGroups = () => {};
			if (!flags.eventGroupNavigationOptimization) {
				unsubEventGroups = dispatch(eventGroupsForSchedulersNavigationListener(flags.eventGroupType));
			}
			const unsubEventAttributeGroups = dispatch(eventAttributeGroupsForNavigationListener());
			const unsubPageContents = dispatch(pageContentsForContentNavigationListener());
			const unsubProductTicketTypes = dispatch(
				ticketTypesForNavigationListener(flags.productNavigationHideMangedByProductgroups)
			);

			return () => {
				unsubNavigations();
				unsubEventGroups();
				unsubEventAttributeGroups();
				unsubPageContents();
				unsubProductTicketTypes();
			};
		}
		return () => {};
	}, [
		dispatch,
		organisationId,
		hasAdminPermission,
		flags.eventGroupNavigationOptimization,
		flags.productNavigationHideMangedByProductgroups,
		flags.eventGroupType
	]);

	useEffect(() => {
		if (!hasUserPermission || !flags.eventGroupNavigationOptimization || !locations) {
			return;
		}
		const entries = Object.entries({
			...eventGroupTypeTranslate,
			[`${eventGroupType.EVENT_EVENTS}---${eventGroupState.HELD}`]: 'Arkiverede begivenheder'
		});
		[{ link: 'events', navigationHead: 'events', navigationHeader: 'user' }].forEach(
			({ navigationHead, navigationHeader, link }) => {
				const eventGroupsByType = {};
				const locationsDivided = [];
				if (locations.length >= 2) {
					entries.forEach(([key, label]) => {
						if (
							!(
								modules.subscriptions &&
								!modules.subscriptions.dividedByLocation &&
								key === eventGroupType.SUBSCRIPTION_EVENTS
							)
						) {
							const _eventGroupUids = [];
							eventGroupsByType[key] = locations.reduce((accLocations, curLocation) => {
								const _eventGroups = eventGroups.filter(_eventGroup => {
									if (
										!_eventGroup.ticketTypes.some(ticketTypeUid =>
											curLocation.ticketTypes.includes(ticketTypeUid)
										)
									) {
										if (
											!(
												((flags.addLocationOnEventGroupsWithoutSimpleProductManagementForEventGroupsEvents &&
													_eventGroup.type === eventGroupType.EVENT_EVENTS) ||
													(flags.addLocationOnEventGroupsWithoutSimpleProductManagement &&
														flags.addLocationOnEventGroupsWithoutSimpleProductManagement
															.split(',')
															.some(id => _eventGroup.id === id))) &&
												_eventGroup.locations &&
												_eventGroup.locations.includes(curLocation.id)
											)
										) {
											return false;
										}
									}
									if (key.includes('---')) {
										const splitKey = key.split('---');
										return (
											_eventGroup.type === splitKey[0] &&
											_eventGroup.state === eventGroupState.HELD
										);
									}
									return _eventGroup.type === key && _eventGroup.state !== eventGroupState.HELD;
								});
								_eventGroupUids.push(..._eventGroups.map(_eventGroup => _eventGroup.id));
								const children = _eventGroups.map(n => ({
									id: `${navigationHead}-${key}-${n.id}`,
									title: n.name,
									type: 'item',
									icon: 'event',
									url: `/apps/${link}/${n.id}`,
									exact: true
								}));
								if (_eventGroups.length > 0) {
									accLocations.push({
										id: `${navigationHead}-${key}-${curLocation.id}`,
										title: curLocation.name,
										type: 'collapse',
										icon: 'location_city',
										children
									});
								}
								return accLocations;
							}, []);

							if (eventGroupsByType[key].length >= 2) {
								locationsDivided.push(key);
								const _eventGroupsWithUnknownLocation = eventGroups
									.filter(_eventGroup => {
										if (_eventGroupUids.includes(_eventGroup.id)) {
											return false;
										}
										if (key.includes('---')) {
											const splitKey = key.split('---');
											return (
												_eventGroup.type === splitKey[0] &&
												_eventGroup.state === eventGroupState.HELD
											);
										}
										return _eventGroup.type === key && _eventGroup.state !== eventGroupState.HELD;
									})
									.map(n => ({
										id: `${navigationHead}-${key}-${n.id}`,
										title: n.name,
										type: 'item',
										icon: 'event',
										url: `/apps/${link}/${n.id}`,
										exact: true
									}));
								if (_eventGroupsWithUnknownLocation.length > 0) {
									eventGroupsByType[key].push({
										id: `${navigationHead}-${key}-unknown`,
										title: 'Ingen lokation',
										type: 'collapse',
										icon: 'location_city',
										children: _eventGroupsWithUnknownLocation
									});
								}
							}
						}
					});
				}
				entries.forEach(([key, label]) => {
					if (!locationsDivided.includes(key)) {
						eventGroupsByType[key] = eventGroups
							.filter(_eventGroup => {
								if (key.includes('---')) {
									const splitKey = key.split('---');
									return (
										_eventGroup.type === splitKey[0] && _eventGroup.state === eventGroupState.HELD
									);
								}
								return _eventGroup.type === key && _eventGroup.state !== eventGroupState.HELD;
							})
							.map(n => ({
								id: `${navigationHead}-${key}-${n.id}`,
								title: n.name,
								type: 'item',
								icon: 'event',
								url: `/apps/${link}/${n.id}`,
								exact: true
							}));
						console.log(key, eventGroupsByType[key]);
					}
				});
				Object.entries(eventGroupsByType).forEach(([key, items]) => {
					items.sort((a, b) => {
						if (a.title < b.title) return -1;
						if (a.title > b.title) return 1;
						return 0;
					});
					items.forEach(item => {
						if (item.children) {
							item.children.sort((a, b) => {
								if (a.title < b.title) return -1;
								if (a.title > b.title) return 1;
								return 0;
							});
						}
					});
				});
				const userNavigation = navigation.find(n => n.id === navigationHeader);
				const update = {};
				Object.entries(eventGroupsByType).forEach(([key, items]) => {
					const eventsNavigation = userNavigation.children.find(n => n.id === `${navigationHead}-${key}`);
					update[key] = eventsNavigation && !_.isEqual(eventsNavigation.children, items);
				});

				Object.entries(eventGroupsByType).forEach(([key, items]) => {
					if (update[key]) {
						removeNavChildrenNoState(dispatch, navigation, `${navigationHead}-${key}`);
					}
				});
				Object.entries(eventGroupsByType).forEach(([key, items]) => {
					if (update[key]) {
						dispatch(
							updateNavigationItemFuse(`${navigationHead}-${key}`, {
								id: `${navigationHead}-${key}`,
								title: eventGroupTypeTranslate[key],
								type: 'collapse',
								icon: 'event',
								children: items
							})
						);
					}
				});
			}
		);
	}, [
		dispatch,
		eventGroups,
		navigation,
		hasUserPermission,
		flags.eventGroupNavigationOptimization,
		locations,
		modules,
		flags.addLocationOnEventGroupsWithoutSimpleProductManagement,
		flags.addLocationOnEventGroupsWithoutSimpleProductManagementForEventGroupsEvents
	]);

	useEffect(() => {
		if (!flags.eventGroupNavigationOptimization || !hasAdminPermission) {
			return;
		}
		const eventGroupsByType = {};
		Object.entries(eventGroupTypeTranslate).forEach(([key, label]) => {
			eventGroupsByType[key] = eventGroups
				.filter(eg => eg.type === key && eg.eventScheduling === eventSchedulingType.AUTOMATIC)
				.map(n => ({
					id: `eventConfig-${key}-${n.id}`,
					title: n.name,
					type: 'item',
					icon: 'event',
					url: `/apps/settings/event-schedulers/${n.id}`,
					exact: true
				}));
		});
		const adminNavigation = _.cloneDeep(navigation.find(n => n.id === 'admin'));
		const userNavigation = adminNavigation.children.find(n => n.id === 'eventConfiguration-new');
		const update = {};
		Object.entries(eventGroupsByType).forEach(([key, items]) => {
			if (userNavigation && userNavigation.children) {
				const eventsConfigNavigation = userNavigation.children.find(n => n.id === `eventConfig-${key}`);
				const eventsSchedulerNavigation = eventsConfigNavigation.children.find(
					n => n.id === `${key}-eventSchedulers`
				);
				update[key] = eventsSchedulerNavigation && !_.isEqual(eventsSchedulerNavigation.children, items);
			}
		});

		Object.entries(eventGroupsByType).forEach(([key, items]) => {
			if (update[key]) {
				removeNavChildrenNoState(dispatch, navigation, `${key}-eventSchedulers`);
			}
		});
		Object.entries(eventGroupsByType).forEach(([key, items]) => {
			if (update[key]) {
				dispatch(
					updateNavigationItemFuse(`${key}-eventSchedulers`, {
						id: `${key}-eventSchedulers`,
						title: 'Planlæggere',
						type: 'collapse',
						icon: 'event_note',
						children: items
					})
				);
			}
		});
	}, [
		eventGroups,
		eventAttributeGroups,
		navigation,
		hasAdminPermission,
		flags.eventGroupNavigationOptimization,
		dispatch
	]);

	useEffect(() => {
		if (!flags.wizardForEventEvents) {
			return;
		}
		dispatch(
			updateNavigationItemFuse(`eventConfig-${eventGroupType.EVENT_EVENTS}`, {
				children: [
					{
						id: `${eventGroupType.EVENT_EVENTS}-create`,
						title: 'Opret',
						type: 'item',
						icon: 'event_note',
						url: `/apps/event-group`,
						auth: authRoles.admin,
						exact: true
					},
					{
						id: `${eventGroupType.EVENT_EVENTS}-show`,
						title: 'Grupper',
						type: 'item',
						icon: 'event_note',
						url: `/apps/event-groups`,
						auth: authRoles.admin,
						exact: true
					}
				]
			})
		);
	}, [dispatch, flags.wizardForEventEvents]);

	useEffect(() => {
		if (!flags.eventGroupNavigationOptimization || !hasAdminPermission) {
			return;
		}
		const adminNavigation = _.cloneDeep(navigation.find(n => n.id === 'admin'));
		const userNavigation = adminNavigation.children.find(n => n.id === 'eventConfiguration-new');
		const unknownConfig =
			userNavigation && userNavigation.children
				? userNavigation.children.find(n => n.id === 'eventConfig-unknown')
				: null;
		const eventGroupsNoType = eventGroups.filter(
			_eventGroup => !_eventGroup.type || !Object.values(eventGroupType).includes(_eventGroup.type)
		);
		if (unknownConfig && eventGroupsNoType.length <= 0) {
			dispatch(removeNavigationItem('eventConfig-unknown'));
		} else if (!unknownConfig && eventGroupsNoType.length > 0) {
			dispatch(
				appendNavigationItem(
					{
						id: `eventConfig-unknown`,
						title: '[LEGACY] Grupper',
						type: 'item',
						icon: 'event_note',
						url: `/apps/settings/event-groups`,
						exact: true
					},
					'eventConfiguration-new'
				)
			);
		}
	}, [eventGroups, navigation, hasAdminPermission, flags.eventGroupNavigationOptimization, dispatch]);

	useEffect(() => {
		if (!flags.eventGroupNavigationOptimization) {
			return () => {};
		}
		const unsubLocations = locationsNavigationListener(_locations => setLocations(_locations));
		const unsubEventAttributeGroups = eventAttributeGroupsListener(_eventAttributeGroups =>
			setEventAttributeGroups(_eventAttributeGroups)
		);
		return () => {
			unsubLocations();
			unsubEventAttributeGroups();
		};
	}, [flags.eventGroupNavigationOptimization]);

	useEffect(() => {
		if (organisationId && hasUserPermission) {
			if (flags.eventGroupNavigationOptimization) {
				dispatch(removeNavigationItem('manualEvents'));
				dispatch(removeNavigationItem('automaticEvents'));
				dispatch(removeNavigationItem('eventConfiguration'));
				const unsubEvents = eventGroupsForEventsNavigationListenerFeatureFlagEventGroupType(_eventGroups => {
					setEventGroups(_eventGroups);
				});
				return () => {
					unsubEvents();
				};
			}
			Object.values(eventGroupType).forEach(key => {
				dispatch(removeNavigationItem(`events-${key}`));
			});
			dispatch(removeNavigationItem(`events-${eventGroupType.EVENT_EVENTS}---${eventGroupState.HELD}`));
			dispatch(removeNavigationItem('eventConfiguration-new'));
			const unsubEvents = dispatch(eventGroupsForEventsNavigationListener(flags.eventGroupType));
			return () => {
				unsubEvents();
			};
		}
		return () => {};
	}, [dispatch, organisationId, hasUserPermission, flags.eventGroupNavigationOptimization, flags.eventGroupType]);

	function handleItemClick(item) {
		if (mdDown) {
			dispatch(navbarCloseMobile());
		}
	}

	return (
		<FuseNavigation
			className={clsx('navigation', props.className)}
			navigation={filteredNavigation}
			layout={props.layout}
			dense={props.dense}
			active={props.active}
			onItemClick={handleItemClick}
		/>
	);
}

Navigation.defaultProps = {
	layout: 'vertical'
};

export default memo(Navigation);
