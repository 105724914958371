import authRoles from '../auth/authRoles';
import eventGroupType, { eventGroupTypeTranslate } from '@ameroservices-platform/shared/enums/eventGroupType';
import eventGroupState from '@ameroservices-platform/attraction-backend/app/main/apps/eventGroups/eventGroupState';

const navigationConfig = [
	{
		id: 'ameroAdmin',
		title: 'Amero Admin',
		type: 'group',
		icon: 'apps',
		auth: authRoles.ameroAdmin,
		children: [
			{
				id: 'organisations',
				title: 'Organisationer',
				type: 'item',
				icon: 'business',
				url: '/apps/organisations',
				auth: authRoles.ameroAdmin,
				exact: true
			},
			{
				id: 'invoice',
				title: 'Fakturering',
				type: 'item',
				icon: 'account_balance',
				url: '/apps/invoice',
				auth: authRoles.ameroAdmin,
				exact: true
			}
		]
	},
	{
		id: 'user',
		title: '',
		type: 'group',
		icon: 'apps',
		requireOrganisation: true,
		auth: authRoles.user,
		children: [
			{
				id: 'dashboard',
				title: 'Dashboard',
				type: 'item',
				icon: 'dashboard',
				url: '/apps/dashboard',
				exact: true
			},
			{
				id: 'tickets',
				title: 'Billetter',
				type: 'item',
				icon: 'local_activity',
				url: '/apps/tickets',
				exact: true
			},
			{
				id: 'subscriptions',
				title: 'Medlemskaber',
				type: 'item',
				icon: 'update',
				url: '/apps/subscriptions',
				exact: true,
				requireModule: 'subscriptions'
			},
			{
				id: 'customers',
				title: 'Kunder',
				type: 'item',
				icon: 'people',
				url: '/apps/customers',
				exact: true
			},
			{
				id: 'carts',
				title: 'Kurve',
				type: 'item',
				icon: 'shopping_basket',
				url: '/apps/carts',
				auth: authRoles.ameroAdmin,
				exact: true
			},
			{
				id: 'orders',
				title: 'Ordrer',
				type: 'item',
				icon: 'shopping_cart',
				url: '/apps/orders',
				exact: true
			},
			{
				id: 'refunds',
				title: 'Refunderinger',
				type: 'item',
				icon: 'keyboard_return',
				url: '/apps/refunds',
				exact: true
			},
			{
				id: 'manualEvents',
				title: 'Manuelle Begivenheder',
				type: 'collapse',
				icon: 'event',
				exact: true,
				children: [],
				requireModule: 'manualEvents'
			},
			{
				id: 'automaticEvents',
				title: 'Automatiske Begivenheder',
				type: 'collapse',
				icon: 'event',
				exact: true,
				children: [],
				requireModule: 'automaticEvents'
			},
			{
				id: `events-${eventGroupType.EVENT_EVENTS}`,
				title: eventGroupTypeTranslate[eventGroupType.EVENT_EVENTS],
				type: 'collapse',
				icon: 'event',
				exact: true,
				children: [],
				requireModule: 'manualEvents'
			},
			{
				id: `events-${eventGroupType.EVENT_EVENTS}---${eventGroupState.HELD}`,
				title: `Arkiverede ${eventGroupTypeTranslate[eventGroupType.EVENT_EVENTS]}`,
				type: 'collapse',
				icon: 'event',
				exact: true,
				children: [],
				requireModule: 'manualEvents'
			},
			{
				id: `events-${eventGroupType.ENTRANCE_EVENTS}`,
				title: eventGroupTypeTranslate[eventGroupType.ENTRANCE_EVENTS],
				type: 'collapse',
				icon: 'event',
				exact: true,
				children: []
			},
			{
				id: `events-${eventGroupType.SUBSCRIPTION_EVENTS}`,
				title: eventGroupTypeTranslate[eventGroupType.SUBSCRIPTION_EVENTS],
				type: 'collapse',
				icon: 'event',
				exact: true,
				children: [],
				requireModule: 'subscriptions'
			}
		]
	},

	{
		id: 'admin-diverse',
		title: '━━━━━━━━━━━━━━━━━━━━━━',
		type: 'group',
		icon: 'apps',
		requireOrganisation: true,
		auth: authRoles.user,
		children: [
			{
				id: 'reports',
				title: 'Rapporter',
				type: 'item',
				icon: 'library_books',
				url: '/apps/reports',
				auth: authRoles.user,
				exact: true
			},
			{
				id: 'recurringScheduledJobs',
				title: 'Gentagende planlagte jobs',
				type: 'item',
				icon: 'repeat',
				url: '/apps/recurring-scheduled-jobs',
				auth: authRoles.admin,
				exact: true
			},
			{
				id: 'statistics',
				title: 'Statistik',
				type: 'item',
				icon: 'equalizer',
				url: '/apps/statistics',
				exact: true,
				auth: authRoles.admin,
				requireModule: 'statistics'
			},
			{
				id: 'budget',
				title: 'Budgetter',
				type: 'item',
				icon: 'forward',
				url: '/apps/budgets',
				exact: true,
				auth: authRoles.admin,
				requireModule: 'statistics'
			},
			{
				id: 'importJobs',
				title: 'Importér',
				type: 'item',
				icon: 'import_export',
				url: '/apps/import-jobs',
				auth: authRoles.admin,
				exact: true
			}
		]
	},
	{
		id: 'admin',
		title: '━━━━━━━━━━━━━━━━━━━━━━',
		type: 'group',
		icon: 'apps',
		requireOrganisation: true,
		auth: authRoles.admin,
		children: [
			{
				id: 'productConfiguration',
				title: 'Produkt Opsætning',
				type: 'collapse',
				icon: 'shop_two',
				auth: authRoles.admin,
				children: [
					{
						id: 'taxGroups',
						title: 'Momskoder',
						type: 'item',
						icon: 'account_balance',
						url: '/apps/settings/vat',
						auth: authRoles.admin,
						exact: true
					},
					{
						id: 'ticketTypes',
						title: 'Produktgrupper',
						type: 'item',
						icon: 'local_activity',
						url: '/apps/settings/product-groups',
						auth: authRoles.admin,
						exact: true
					},
					{
						id: 'products',
						title: 'Produkter',
						type: 'collapse',
						icon: 'shop_two',
						auth: authRoles.admin,
						exact: true
					},
					{
						id: 'followProducts',
						title: 'Følgeprodukter',
						type: 'item',
						icon: 'shopping_bag',
						url: '/apps/follow-products',
						auth: authRoles.admin,
						exact: true
					},
					{
						id: 'discountCodeTypes',
						title: 'Rabatkode-typer',
						type: 'item',
						icon: 'local_offer',
						url: '/apps/settings/discount-code-types',
						auth: authRoles.admin,
						exact: true,
						requireModule: 'discountCodes'
					}
				]
			},
			{
				id: 'memberConfiguration',
				title: 'Medlems Opsætning',
				type: 'collapse',
				icon: 'group',
				requireModule: 'subscriptions',
				auth: authRoles.admin,
				children: [
					{
						id: 'subscriptionTypes',
						title: 'Medlemskabstyper',
						type: 'item',
						icon: '360',
						url: '/apps/settings/subscription-types',
						auth: authRoles.admin,
						exact: true
					}
				]
			},
			{
				id: 'eventConfiguration',
				title: 'Begivenhedsopsætning',
				type: 'collapse',
				icon: 'event',
				requireModule: ['automaticEvents', 'manualEvents'],
				auth: authRoles.admin,
				children: [
					{
						id: 'eventGroups',
						title: 'Grupper',
						type: 'item',
						icon: 'event_note',
						url: '/apps/settings/event-groups',
						auth: authRoles.admin,
						exact: true
					},
					{
						id: 'eventSchedulers',
						title: 'Planlægning',
						type: 'collapse',
						icon: 'event_note',
						requireModule: 'automaticEvents',
						auth: authRoles.admin,
						exact: true
					},
					{
						id: 'eventAttributeGroups',
						title: 'Attribut Grupper',
						type: 'collapse',
						icon: 'edit_attributes',
						exact: true,
						auth: authRoles.admin,
						children: []
					}
				]
			},
			{
				id: 'eventConfiguration-new',
				title: 'Begivenhedsopsætning',
				type: 'collapse',
				icon: 'event',
				requireModule: ['automaticEvents', 'manualEvents'],
				auth: authRoles.admin,
				children: [
					{
						id: `eventConfig-${eventGroupType.ENTRANCE_EVENTS}`,
						title: eventGroupTypeTranslate[eventGroupType.ENTRANCE_EVENTS],
						type: 'collapse',
						icon: 'event',
						exact: true,
						auth: authRoles.admin,
						children: [
							{
								id: `${eventGroupType.ENTRANCE_EVENTS}-eventGroups`,
								title: 'Grupper',
								type: 'item',
								icon: 'event_note',
								url: `/apps/settings/event-groups/${eventGroupType.ENTRANCE_EVENTS}`,
								auth: authRoles.admin,
								exact: true
							},
							{
								id: `${eventGroupType.ENTRANCE_EVENTS}-eventSchedulers`,
								title: 'Planlægning',
								type: 'collapse',
								icon: 'event_note',
								requireModule: 'automaticEvents',
								auth: authRoles.admin,
								exact: true
							}
						]
					},
					{
						id: `eventConfig-${eventGroupType.EVENT_EVENTS}`,
						title: eventGroupTypeTranslate[eventGroupType.EVENT_EVENTS],
						type: 'collapse',
						icon: 'event',
						exact: true,
						auth: authRoles.admin,
						children: [
							{
								id: `${eventGroupType.EVENT_EVENTS}-eventGroups`,
								title: 'Grupper',
								type: 'item',
								icon: 'event_note',
								url: `/apps/settings/event-groups/${eventGroupType.EVENT_EVENTS}`,
								auth: authRoles.admin,
								exact: true
							},
							{
								id: `${eventGroupType.EVENT_EVENTS}-eventSchedulers`,
								title: 'Planlægning',
								type: 'collapse',
								icon: 'event_note',
								requireModule: 'automaticEvents',
								auth: authRoles.admin,
								exact: true
							}
						],
						requireModule: 'manualEvents'
					},
					{
						id: `eventConfig-${eventGroupType.SUBSCRIPTION_EVENTS}`,
						title: eventGroupTypeTranslate[eventGroupType.SUBSCRIPTION_EVENTS],
						type: 'collapse',
						icon: 'event',
						exact: true,
						auth: authRoles.admin,
						children: [
							{
								id: `${eventGroupType.SUBSCRIPTION_EVENTS}-eventGroups`,
								title: 'Grupper',
								type: 'item',
								icon: 'event_note',
								url: `/apps/settings/event-groups/${eventGroupType.SUBSCRIPTION_EVENTS}`,
								auth: authRoles.admin,
								exact: true
							},
							{
								id: `${eventGroupType.SUBSCRIPTION_EVENTS}-eventSchedulers`,
								title: 'Planlægning',
								type: 'collapse',
								icon: 'event_note',
								requireModule: 'automaticEvents',
								auth: authRoles.admin,
								exact: true
							}
						],
						requireModule: 'subscriptions'
					},
					{
						id: 'eventAttributeGroups',
						title: 'Attribut Grupper',
						type: 'collapse',
						icon: 'edit_attributes',
						exact: true,
						auth: authRoles.admin,
						children: []
					}
				]
			},
			{
				id: 'content',
				title: 'Frontend Opsætning',
				type: 'collapse',
				icon: 'wallpaper',
				auth: authRoles.admin,
				children: [
					{
						id: 'pages',
						title: 'Sider',
						type: 'item',
						icon: 'web',
						url: '/apps/frontend-settings/pages',
						exact: true,
						auth: authRoles.ameroAdmin
					},
					{
						id: 'pageContents',
						title: 'Side indhold',
						type: 'collapse',
						auth: authRoles.admin,
						icon: 'web'
					},
					{
						id: 'theme',
						title: 'Tema',
						type: 'item',
						icon: 'color_lens',
						url: '/apps/frontend-settings/theme',
						auth: authRoles.admin,
						exact: true
					},
					{
						id: 'faqs',
						title: 'FAQ',
						type: 'item',
						icon: 'question_answer',
						url: '/apps/frontend-settings/faqs',
						auth: authRoles.admin,
						exact: true
					},
					{
						id: 'navigations',
						title: 'Navigationer',
						type: 'collapse',
						icon: 'wallpaper',
						auth: authRoles.admin,
						children: []
					},
					{
						id: 'footer',
						title: 'Footer',
						type: 'item',
						icon: 'settings',
						url: '/apps/frontend-settings/footer',
						auth: authRoles.admin,
						exact: true
					},
					{
						id: 'header',
						title: 'Header',
						type: 'item',
						icon: 'settings',
						url: '/apps/frontend-settings/header',
						auth: authRoles.admin,
						exact: true
					}
				]
			},
			{
				id: 'flexposSettings',
				title: 'FlexPOS Opsætning',
				type: 'collapse',
				icon: 'computer',
				auth: authRoles.admin,
				children: [
					{
						id: 'flexposSettings-ticketSale',
						title: 'Billetsalg',
						type: 'item',
						icon: 'computer',
						url: '/apps/flexpos-settings/ticket-sale',
						auth: authRoles.admin,
						exact: true
					},
					{
						id: 'flexposSettings-members',
						title: 'Medlemsmodul',
						type: 'item',
						icon: 'group',
						url: '/apps/flexpos-settings/members',
						exact: true,
						auth: authRoles.admin,
						requireModule: 'subscriptions'
					}
				]
			},
			{
				id: 'systemConfiguration',
				title: 'System Opsætning',
				type: 'collapse',
				icon: 'settings',
				auth: authRoles.admin,
				children: [
					{
						id: 'incrementalNumbers',
						title: 'Fortløbende numre',
						type: 'item',
						icon: 'plus_one',
						url: '/apps/settings/incremental-numbers',
						auth: authRoles.admin,
						exact: true
					},
					{
						id: 'mails',
						title: 'Kommunikationsskabeloner',
						type: 'item',
						icon: 'mail',
						url: '/apps/communication-templates',
						auth: authRoles.admin,
						exact: true
					},
					{
						id: 'locations',
						title: 'Lokationer',
						type: 'item',
						icon: 'location_city',
						url: '/apps/settings/locations',
						auth: authRoles.admin,
						exact: true
					},
					{
						id: 'lists',
						title: 'Lister',
						type: 'item',
						icon: 'list',
						url: '/apps/settings/lists',
						auth: authRoles.ameroAdmin,
						exact: true
					},
					{
						id: 'countries',
						title: 'Lande',
						type: 'item',
						icon: 'public',
						url: '/apps/settings/countries',
						exact: true,
						auth: authRoles.ameroAdmin
					},
					{
						id: 'financeDepartments',
						title: 'Finansafdelinger',
						type: 'item',
						icon: 'credit_card',
						url: '/apps/finance-departments',
						exact: true,
						requireModule: 'statistics',
						auth: authRoles.admin
					}
				]
			}
		]
	},
	{
		id: 'links',
		title: 'Links',
		type: 'group',
		icon: 'apps',
		children: [
			{
				id: 'link-status',
				title: 'Status',
				type: 'item',
				icon: 'signal_wifi_3_bar',
				url: '/status',
				exact: true
			},
			{
				id: 'link-docs',
				title: 'Dokumentation',
				type: 'item',
				icon: 'receipt',
				url: '/docs',
				exact: true
			}
		]
	}
];

export default navigationConfig;
