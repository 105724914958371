import React, { useState } from 'react';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';
import Icon from '@mui/material/Icon';
import { Alert, TextField } from '@mui/material';
import LoadingButton from './LoadingButton';
import clsx from 'clsx';

function ConfirmationDialog(props) {
	const {
		onClose,
		open,
		content,
		okButton,
		cancelButton,
		loading,
		maxWidth,
		fullWidth,
		buttons,
		icon,
		disableEscapeKeyDown,
		disableButtons,
		needToType,
		hintToType,
		mainTheme,
		disableRoundButtons,
		fullWidthButtons,
		semiRoundButtons,
		iconColor,
		...other
	} = props;
	const [confirmation, setConfirmation] = useState('');
	if (!mainTheme) {
		console.trace();
		throw new Error('mainTheme is missing in props');
	}

	const handleCancel = () => {
		onClose(false);
		setConfirmation('');
	};

	const handleOk = override => {
		if (needToType && !confirmation && needToType !== confirmation) {
			handleCancel();
		} else {
			onClose(override === undefined ? true : override);
		}
		setConfirmation('');
	};

	function handleSelect(id) {
		let sel;
		let range;
		const el = document.getElementById(id); // get element id
		if (window.getSelection && document.createRange) {
			// Browser compatibility
			sel = window.getSelection();
			if (sel.toString() === '') {
				// no text selection
				window.setTimeout(() => {
					range = document.createRange(); // range object
					range.selectNodeContents(el); // sets Range
					sel.removeAllRanges(); // remove all ranges from selection
					sel.addRange(range); // add Range to a Selection.
				}, 1);
			}
		} else if (document.selection) {
			// older ie
			sel = document.selection.createRange();
			if (sel.text === '') {
				// no text selection
				range = document.body.createTextRange(); // Creates TextRange object
				range.moveToElementText(el); // sets Range
				range.select(); // make selection.
			}
		}
	}

	return (
		<ThemeProvider theme={mainTheme}>
			<Dialog
				onClose={handleCancel}
				disableEscapeKeyDown={
					disableEscapeKeyDown === undefined || disableEscapeKeyDown === null ? true : disableEscapeKeyDown
				}
				maxWidth={maxWidth || 'xs'}
				fullWidth={fullWidth || false}
				aria-labelledby="confirmation-dialog-title"
				open={open || false}
				{...other}
			>
				<DialogContent dividers>
					<div className="flex flex-col items-center justify-center p-32">
						{icon !== false && (
							<div className="m-32">
								<Icon className="text-96" color={iconColor || 'action'}>
									{icon || 'warning'}
								</Icon>
							</div>
						)}

						<div className="text-center mb-16 text-16 w-full" color="textSecondary">
							{content}
						</div>
						{needToType && (
							<Alert
								severity={needToType !== confirmation ? 'error' : 'success'}
								className="w-full"
								classes={{ message: 'w-full' }}
							>
								<Typography>
									Skriv {/* eslint-disable-next-line */}
									{hintToType ? (
										hintToType
									) : (
										<span
											className="font-bold"
											id="security-text"
											onClick={() => handleSelect('security-text')}
										>
											{needToType}
										</span>
									)}{' '}
									i feltet under for at bekræfte
								</Typography>
								<TextField
									className="mt-10 w-full"
									variant="outlined"
									label="Bekræftelse"
									size="small"
									fullWidth
									value={confirmation}
									onChange={e => setConfirmation(e.currentTarget.value)}
								/>
							</Alert>
						)}
					</div>
				</DialogContent>
				{buttons !== false && (
					<>
						{buttons ? (
							<DialogActions>
								{buttons.map(button =>
									button.result !== undefined && button.result !== null && button.result !== false ? (
										<React.Fragment key={`${button.label}-${button.result}`}>
											<LoadingButton
												onClick={() => handleOk(button.result)}
												variant={button.variant || 'contained'}
												color={button.color || 'primary'}
												loading={loading || false}
												disableLoadingOnClick
												disabled={!!disableButtons || button.disabled}
												classes={disableRoundButtons ? { button: 'rounded-none' } : undefined}
											>
												{button.label}
											</LoadingButton>
										</React.Fragment>
									) : (
										<React.Fragment key={`${button.label}-${button.result}`}>
											<LoadingButton
												onClick={() => handleOk(button.result)}
												variant={button.variant || 'contained'}
												color={button.color || 'primary'}
												loading={loading || false}
												disableLoadingOnClick
												disabled={button.disabled}
												classes={disableRoundButtons ? { button: 'rounded-none' } : undefined}
											>
												{button.label}
											</LoadingButton>
										</React.Fragment>
									)
								)}
							</DialogActions>
						) : (
							<DialogActions className={clsx(fullWidthButtons && 'flex justify-between items-center')}>
								{cancelButton && cancelButton !== false && (
									<Button
										autoFocus
										variant="contained"
										onClick={handleCancel}
										color="inherit"
										className={clsx(
											disableRoundButtons && 'rounded-r-none',
											semiRoundButtons && 'rounded-md',
											fullWidthButtons && 'w-1/2'
										)}
									>
										{cancelButton !== true && cancelButton !== false ? cancelButton : 'Annuller'}
									</Button>
								)}
								<LoadingButton
									onClick={handleOk}
									variant="contained"
									color="primary"
									loading={loading || false}
									disableLoadingOnClick
									disabled={!!disableButtons || (needToType && needToType !== confirmation)}
									classes={{
										wrapper: clsx(fullWidthButtons && 'w-1/2'),
										button: clsx(
											disableRoundButtons && 'rounded-l-none',
											semiRoundButtons && 'rounded-md',
											fullWidthButtons && 'w-full'
										)
									}}
								>
									{okButton || 'Ja'}
								</LoadingButton>
							</DialogActions>
						)}
					</>
				)}
			</Dialog>
		</ThemeProvider>
	);
}
export default ConfirmationDialog;
