import error404PageConfig from '@ameroservices-platform/attraction-backend/app/main/apps/error/404/error404PageConfig';
import error500PageConfig from '@ameroservices-platform/attraction-backend/app/main/apps/error/500/error500PageConfig';
import OrganisationsAppConfig from './organisations/OrganisationsAppConfig';
import DashboardAppConfig from './dashboard/DashboardAppConfig';
import StatisticsAppConfig from './statistics/StatisticsAppConfig';
import CustomersAppConfig from './customers/CustomersAppConfig';
import FrontendSettingsAppConfig from './frontendSettings/FrontendSettingsAppConfig';
import TicketsAppConfig from './tickets/TicketsAppConfig';
import OrdersAppConfig from './orders/OrdersAppConfig';
import ProductsAppConfig from './products/ProductsAppConfig';
import SubscriptionsAppConfig from './subscriptions/SubscriptionsAppConfig';
import EventsAppConfig from './events/EventsAppConfig';
import EventSchedulersAppConfig from './eventSchedulers/EventSchedulersAppConfig';
import EventGroupsAppConfig from './eventGroups/EventGroupsAppConfig';
import NewEventGroupsAppConfig from './event-groups/EventGroupsAppConfig';
import SettingsAppConfig from './settings/SettingsAppConfig';
import ImportJobsAppConfig from './importJobs/ImportJobsAppConfig';
import ExportJobsAppConfig from './exportJobs/ExportJobsAppConfig';
import EventAttributeGroupsConfig from './eventAttributeGroups/EventAttributeGroupsAppConfig';
import SysAdminAppConfig from './sysAdmin/SysAdminAppConfig';
import FlexposSettingsAppConfig from './flexposSettings/FlexposSettingsAppConfig';
import InvoiceAppConfig from './invoicing/invoicingAppConfig';
import FollowProductsAppConfig from './followProducts/FollowProductsAppConfig';
import FinanceDepartmentsAppConfig from './financeDepartments/FinanceDepartmentsAppConfig';
import BudgetsAppConfig from './budgets/BudgetsAppConfig';
import RedirectAppConfig from './redirect/RedirectAppConfig';
import RecurringScheduledJobsConfig from './recurringScheduledJobs/RecurringScheduledJobsConfig';
import CommunicationTemplatesAppConfig from '@ameroservices-platform/attraction-backend/app/main/apps/communication-templates/CommunicationTemplatesAppConfig';

const appsConfigs = [
	InvoiceAppConfig,
	OrganisationsAppConfig,
	DashboardAppConfig,
	CustomersAppConfig,
	TicketsAppConfig,
	TicketsAppConfig,
	OrdersAppConfig,
	ProductsAppConfig,
	FollowProductsAppConfig,
	SubscriptionsAppConfig,
	EventsAppConfig,
	EventSchedulersAppConfig,
	EventGroupsAppConfig,
	ImportJobsAppConfig,
	ExportJobsAppConfig,
	EventAttributeGroupsConfig,
	SysAdminAppConfig,
	FlexposSettingsAppConfig,
	error404PageConfig,
	error500PageConfig,
	StatisticsAppConfig,
	FinanceDepartmentsAppConfig,
	BudgetsAppConfig,
	RedirectAppConfig,
	RecurringScheduledJobsConfig,
	NewEventGroupsAppConfig,
	CommunicationTemplatesAppConfig,
	...SettingsAppConfig,
	...FrontendSettingsAppConfig
];

export default appsConfigs;
